'use client';

import { useMemo } from 'react';

import {
  Root,
  TopBar,
  Container,
  Burger,
  Logo,
  Searchbar,
  Actions,
  Navigation,
} from '@leroy-merlin-br/backpack-header';
import { InstantSearchNext } from 'react-instantsearch-nextjs';

import { type CategoriesTree } from '@/infra/services/categories/model';
import { useAnalyticsTags } from '@/presenters/hooks/algolia/useAnalyticsTags';
import { useGlobalStore } from '@/presenters/store/global';
import {
  indexName,
  insightsConfig,
  searchClient,
  retailMediaIndexName,
} from '@/utils/algolia';

import { locationContextAdapter, userAdapter } from './adapters';
import { onUserRecontext, redirect } from './Header.utils';
import { locationRepository, navigationRepository } from './repositories';

export interface HeaderProps {
  categories: CategoriesTree;
}

export default function Header(props: HeaderProps) {
  const { categories } = props;

  const analyticsTags = useAnalyticsTags();
  const userState = useGlobalStore((state) => state.user);

  const user = useMemo(() => {
    return userAdapter(userState);
  }, [userState]);

  const locationContext = useMemo(() => {
    return locationContextAdapter(userState.session);
  }, [userState]);

  return (
    <InstantSearchNext
      insights={insightsConfig}
      searchClient={searchClient}
      indexName={indexName}
      future={{ preserveSharedStateOnUnmount: true }}
    >
      <Root
        data-testid="header"
        user={user}
        locationContext={locationContext}
        cartItems={Number(userState.resources?.cartItems || '0')}
        routing
      >
        <TopBar
          handleLocationRecontext={onUserRecontext}
          locationRepository={locationRepository}
        />

        <Container>
          <Burger />
          <Logo />
          <Searchbar
            algolia={{
              indexName: indexName,
              insightsClient: insightsConfig.insightsClient,
              searchClient,
              retailMediaIndexName,
              queries: [{ params: { analyticsTags }, indexName }],
            }}
          />
          <Actions
            providers={{
              disable: ['facebook'],
            }}
            onClickCart={() => redirect('/carrinho')}
          />
        </Container>

        <Navigation
          data={categories}
          navigationRepository={navigationRepository}
        />
      </Root>
    </InstantSearchNext>
  );
}
